import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import ServiceList from '../../components/services/serviceList'
import * as S from '../../styles/pages/servicesStyle'
import * as G from '../../styles/globalStyle'

export default function BodyServices({ data }) {
  return (
    <Layout>
      <S.ServicesSection>
        <G.Container>
          <S.ListTitle>Zabiegi Na Ciało</S.ListTitle>
          <ServiceList services={data.servicesList.edges} />
        </G.Container>
      </S.ServicesSection>
    </Layout>
  )
}

export const query = graphql`
  query BodyServicesQuery {
    servicesList: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "markdowns/zabiegi/body/.*.md/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
          }
        }
      }
    }
  }
`
